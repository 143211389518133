<template>
  <div class="trading">
    <div class="td-bg-grid"></div>
    <div class="td-bg-pipe">
      <div class="td-bg-dex">
        <img src="@/assets/images/derivatives/trading/der_02_dex.png" alt="" />
      </div>
    </div>
    <div class="td-img">
      <div class="td-robot">
        <img src="@/assets/images/derivatives/trading/der_02_xrobot_b.png" alt="" />
      </div>
      <div class="td-coin-box">
        <div class="td-coin" v-for="(item, key) of coin" :key="key">
          <img :src="require('@/assets/images/derivatives/trading/der_02_coin_' + item)" alt="" />
        </div>
      </div>
    </div>
    <div class="td-main-context" ref="textBox1">
      <div class="common-transition-box">
        <h2 class="td-title" ref="title1">Trading method</h2>
      </div>
      <div class="common-text td-note" ref="text1">
        With decentralized leveraged trading tools, users can trade using f(x) Wallet or on the official website, and
        due to the use of a perpetual contract fee rate adjustment mechanism, all assets can be traded 24 hours, but
        both parties need to pay attention to the fee rate time to determine their trading strategy.
      </div>
      <div class="td-btn">
        <CButton type="comic" :height="42" @click="goOut">
          <div class="common-btn-content">
            The details of MarginX
            <div class="common-btn-icon">
              <i class="common-icon-jumpB"></i>
            </div>
          </div>
        </CButton>
      </div>
    </div>
    <div class="td-sub-context" ref="textBox2">
      <div class="common-transition-box">
        <h2 class="td-title" ref="title2">HOW TO BRIDGE STABLECOINS?</h2>
      </div>
      <div class="common-text td-note" ref="text2">
        Investors can use stablecoins from Ethereum to generate an equivalent USD in Function X network. Your original
        Ethereum stablecoins will also receive investment returns from savings in places like Aave. And at the same time
        use your bridged stablecoins on Function X network will be used to trade.
      </div>
    </div>
  </div>
</template>

<script>
import { animeTitleShow } from '@/tools/utils'
import { gsapDestroy } from '@/tools/mixin'
import CButton from '@/components/common/CButton'

export default {
  name: 'Trading',
  components: { CButton },
  data() {
    return {
      coin: ['a.png', 'b.png', 'c.png']
    }
  },
  mixins: [gsapDestroy],
  methods: {
    goOut() {
      window.open('https://www.marginx.io')
    }
  },
  mounted() {
    document.fonts.ready.then(() => {
      this.commonGsapArray[0] = animeTitleShow({
        titleEl: this.$refs.title1,
        textEl: this.$refs.text1,
        scrollTrigger: {
          trigger: this.$refs.textBox1,
          start: 'bottom bottom',
          end: 'bottom bottom'
        }
      })

      this.commonGsapArray[1] = animeTitleShow({
        titleEl: this.$refs.title2,
        textEl: this.$refs.text2,
        scrollTrigger: {
          trigger: this.$refs.textBox2,
          start: 'bottom bottom',
          end: 'bottom bottom'
        }
      })
    })
  }
}
</script>

<style scoped lang="scss">
$panelMargin: 63px;
$panelBottom: 79px;
$panelTop: 57px;
$standardWidth: 1440px;
$coinBoxHeight: 155px;
$coinWidth: 37px;
$coinHeight: 44px;
$coinDelay: 1;
$coinDur: 0.4;
@mixin center-width($side) {
  width: $standardWidth - $side * 2;
  margin-left: auto;
  margin-right: auto;
}
.trading {
  width: 100%;
  padding-top: $panelTop;
  padding-bottom: $panelBottom;
  position: relative;
  overflow: hidden;
  .td-title {
    width: 534px;
  }
  .td-note {
    margin-top: 8px;
  }
  .td-main-context {
    @include center-width(186px);
    position: relative;
    z-index: 5;
    padding-top: 8px;
    .td-note {
      width: 513px;
    }
  }
  .td-btn {
    margin-top: 24px;
  }
  .td-sub-context {
    @include center-width(160px);
    margin-top: 353px;
    position: relative;
    z-index: 5;
    .td-title {
      margin-left: auto;
    }
    .td-note {
      width: 534px;
      margin-left: auto;
    }
  }
  .td-bg-grid {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 511px;
    background-image: url('~@/assets/images/derivatives/trading/der_02_wall.gif');
    background-size: auto 100%;
    background-position: center bottom;
  }
  .td-bg-pipe {
    position: absolute;
    top: $panelTop;
    left: 0;
    right: 0;
    height: 538px;
    background-image: url('~@/assets/images/derivatives/trading/der_02_pipeline.gif');
    background-size: auto 100%;
    background-position: center bottom;
  }
  .td-bg-dex {
    position: absolute;
    top: 19px;
    left: 50%;
    transform: translateX(50px);
    width: 301px;
  }
  .td-img {
    @include center-width(277px);
    position: absolute;
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);
  }
  .td-robot {
    width: 355px;
  }
  .td-coin-box {
    position: absolute;
    height: $coinBoxHeight;
    width: $coinWidth;
    left: 218px;
    top: -$coinBoxHeight + 18px;
    overflow: hidden;
  }
  .td-coin {
    position: absolute;
    top: -$coinHeight;
    width: $coinWidth;
    &:nth-of-type(1) {
      animation: #{(($coinDelay + $coinDur) * 3) + 's'} coin-down 0s linear infinite;
    }
    &:nth-of-type(2) {
      animation: #{(($coinDelay + $coinDur) * 3) + 's'} coin-down #{$coinDelay + $coinDur + 's'} linear infinite;
    }
    &:nth-of-type(3) {
      animation: #{(($coinDelay + $coinDur) * 3) + 's'} coin-down #{(($coinDelay + $coinDur) * 2) + 's'} linear infinite;
    }
  }
}
@keyframes coin-down {
  0% {
    transform: translateY(0);
  }
  #{$coinDur / (($coinDelay + $coinDur) * 3) * 100 + '%'} {
    transform: translateY($coinBoxHeight + $coinHeight);
  }
  100% {
    transform: translateY($coinBoxHeight + $coinHeight);
  }
}
</style>
