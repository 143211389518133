<template>
  <div class="contract">
    <div class="ct-main-text" ref="textBox1">
      <div class="common-transition-box">
        <h2 class="ct-title" ref="title1">
          Providing a 24x7 tokenized stocks perpetual contract trading platform
        </h2>
      </div>
      <div class="common-text ct-note" ref="text1">
        Users can bridge ERC20 stablecoins into Function X network's f(x)Bridge
        cross-chain module. Thereafter, users can trade stock-based perpetual
        contract on Function X DEX chain around the clock using these bridged
        stablecoins. The process is decentralized and transparent. Initially
        Function X DEX chain will offer popular assets for trading and gradually
        open to new assets listing via community voting and governance.
      </div>
    </div>
    <div class="ct-sub-content">
      <div class="ct-img">
        <div class="ct-machine" ref="machine">
          <img
            src="@/assets/images/derivatives/contract/der_02_machine.gif"
            alt=""
          />
          <div class="ct-robot">
            <img
              src="@/assets/images/derivatives/contract/der_02_xrobot_a.png"
              alt=""
            />
          </div>
          <div class="ct-ray">
            <svg width="190px" height="43px" viewBox="0 0 190 43">
              <g
                stroke="#454545"
                stroke-width="2"
                fill-rule="evenodd"
                fill="none"
              >
                <path
                  d="M 1 31.226619 L 42.878 26.998 L 54.879 2 L 78.452 40 L 91.798 9.188 L 98.975 21.130 L 189,12.234"
                  stroke-dasharray="120 260"
                  :stroke-dashoffset="rayOffset"
                ></path>
              </g>
            </svg>
          </div>
        </div>
      </div>
      <div class="ct-context" ref="textBox2">
        <div class="ct-change">
          <div class="ct-coin-box">
            <div
              class="ct-coin"
              :style="{
                opacity: coinOpacity,
                transform: `translateY(${coinY}px)`
              }"
            >
              <img
                src="@/assets/images/derivatives/contract/der_02_coin.png"
                alt=""
              />
            </div>
          </div>
          <div class="ct-stone-box">
            <div class="ct-stone" :style="{ opacity: stoneROpacity }">
              <img
                src="@/assets/images/derivatives/contract/der_02_stone.png"
                alt=""
              />
            </div>
            <div
              class="ct-stone"
              :style="{
                opacity: stoneLOpacity,
                transform: `translateX(${stoneLX}px)`
              }"
            >
              <img
                src="@/assets/images/derivatives/contract/der_02_stone.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="common-transition-box">
          <h2 class="ct-sub-title" ref="title2">
            The advantage of stock-based perpetual contract
          </h2>
        </div>
        <div class="common-text ct-sub-note" ref="text2">
          Decentralized trading exchanges not only trades cryptocurrency assets,
          but also stocks, commodities and others, making this process more
          decentralized without human intervention and linked to the price of
          the asset itself. We will adopt a perpetual contract method that uses
          the price of the asset at three different time - the time when
          transaction starts, during transaction, and when the transaction is
          closed to feed the price, and automatically adjust the handling fee
          rate of the long and short sides, so that the trading price is at
          market price.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap, ScrollTrigger } from '@/tools/gsap'
import { animeTitleShow } from '@/tools/utils'
import { gsapDestroy } from '@/tools/mixin'
const rayLineLength = 120
const rayBlankLength = 260
export default {
  name: 'Contract',
  data() {
    return {
      rayOffset: -rayBlankLength,
      coinOpacity: 0,
      coinY: 0,
      stoneLOpacity: 1,
      stoneLX: -235,
      stoneROpacity: 1
    }
  },
  mixins: [gsapDestroy],
  mounted() {
    const tl = gsap.timeline({
      repeat: -1,
      paused: true
    })
    const ray = gsap.to(this.$data, {
      rayOffset: -rayBlankLength - rayBlankLength - rayLineLength,
      duration: 0.5
    })
    const stoneHide = gsap.to(this.$data, {
      stoneLOpacity: 0,
      duration: 0.1
    })
    const coinShow = gsap.to(this.$data, {
      coinOpacity: 1,
      duration: 0.1
    })
    const coinDown = gsap.to(this.$data, {
      coinY: 100,
      coinOpacity: 0,
      duration: 0.1
    })
    const stoneMove = gsap.to(this.$data, {
      startAt: {
        stoneLX: 0,
        stoneLOpacity: 0.99
      },
      stoneLX: -235,
      stoneLOpacity: 1,
      duration: 0.3
    })
    const stoneRHide = gsap.to(this.$data, {
      stoneROpacity: 0,
      duration: 0.01
    })
    const stoneRshow = gsap.to(this.$data, {
      startAt: {
        stoneROpacity: 0
      },
      stoneROpacity: 1,
      duration: 0.3
    })
    tl.add(ray)
    tl.add(stoneHide, '-=0.1')
    tl.add(coinShow, '-=0.1')
    tl.add(coinDown, '+=0.5')
    tl.add(stoneMove)
    tl.add(stoneRHide, '-=0.3')
    tl.add(stoneRshow, '-=0.1')
    this.commonGsapArray[0] = tl

    document.fonts.ready.then(() => {
      this.commonGsapArray[1] = ScrollTrigger.create({
        trigger: this.$refs.machine,
        start: 'bottom-=100px bottom',
        end: 'bottom-=100px bottom',
        markers: process.env.NODE_ENV === 'development',
        onEnter: () => {
          tl.play()
        }
      })

      this.commonGsapArray[2] = animeTitleShow({
        titleEl: this.$refs.title1,
        textEl: this.$refs.text1,
        scrollTrigger: {
          trigger: this.$refs.textBox1,
          start: 'bottom bottom',
          end: 'bottom bottom'
        }
      })
      this.commonGsapArray[3] = animeTitleShow({
        titleEl: this.$refs.title2,
        textEl: this.$refs.text2,
        scrollTrigger: {
          trigger: this.$refs.textBox2,
          start: 'bottom bottom',
          end: 'bottom bottom'
        }
      })
    })
  }
}
</script>

<style scoped lang="scss">
@mixin coin-box($wh) {
  width: $wh;
  height: $wh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: 100%;
}
.contract {
  width: 100%;
  padding-top: 51px;
  padding-bottom: 57px;
  .ct-main-text {
    width: 960px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  .ct-title {
  }
  .ct-note {
    width: 889px;
    margin-top: 8px;
  }

  .ct-sub-content {
    width: 1150px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 98px;
  }
  .ct-img {
    width: 568px;
    flex-shrink: 0;
  }
  .ct-context {
    margin-left: 33px;
    width: 534px;
  }
  .ct-change {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .ct-coin-box {
    @include coin-box(200px);
    background-image: url('~@/assets/images/derivatives/contract/der_02_bubble_a.png');
  }
  .ct-coin {
    width: 54px;
  }
  .ct-stone-box {
    @include coin-box(150px);
    background-image: url('~@/assets/images/derivatives/contract/der_02_bubble_b.png');
    margin-left: 60px;
    position: relative;
  }
  .ct-stone {
    position: absolute;
    width: 63px;
  }
  .ct-machine {
    width: 468px;
    margin-top: 112px;
    position: relative;
  }
  .ct-robot {
    position: absolute;
    right: -54px;
    bottom: -97px;
    width: 210px;
  }
  .ct-ray {
    position: absolute;
    top: -18px;
    left: calc(100% + 6px);
    width: 188px;
  }
  .ct-sub-title {
    margin-top: 100px;
  }
  .ct-sub-note {
    margin-top: 8px;
  }
}
</style>
