<template>
  <div class="benefit">
    <div class="bf-context" ref="textBox">
      <div class="common-transition-box">
        <h2 class="bf-title" ref="title">
          Double benefit - New investment opportunities & capital appreciation
        </h2>
      </div>
      <div class="common-text bf-note" ref="text">
        Uses can deposit their $ETH, $FX, $PUNDIX, $DAI, $USDT, $USDC or other assets into the specific f(x) collateral
        smart contract.The f(x) collateral smart contract will not only automatically divert the deposited principal
        assets to the respective protocol to earn interest but also collateralise the deposited assets into f(x)USD to
        trade at the new platform. Users can enjoy the capital appreciation while exploring the new investment
        opportunities with just a few simple steps.
      </div>
    </div>
    <div class="bf-content">
      <div class="bf-action-box">
        <div class="bf-action font-black">
          DEPOSIT IN>
          <div class="bf-action-line"></div>
          <div class="bf-action-line"></div>
        </div>
        <div class="bf-action font-black">
          CROSS-CHAIN GENERATION>
          <div class="bf-action-line"></div>
          <div class="bf-action-line"></div>
        </div>
      </div>
      <div class="bf-panel-box">
        <div class="bf-panel">
          <div class="bf-panel-head">
            <div class="bf-panel-head-icon">
              <i class="common-icon-token"></i>
              <div class="bf-panel-head-angle">
                <i class="common-icon-eth"></i>
              </div>
            </div>
            <div class="bf-panel-head-text font-black">
              ERC20 Tokens You Hold
            </div>
          </div>
          <div class="bf-coin-list bf-coin-erc20">
            <div class="bf-coin-item" v-for="(item, key) in coinList" :key="key">
              <div class="bf-coin-icon">
                <i :class="[item.icon]"></i>
              </div>
              <div class="bf-coin-name font-black">{{ item.name }}</div>
            </div>
          </div>
          <div class="bf-panel-line">
            <img src="@/assets/images/derivatives/benefit/der_03_line.png" alt="" />
          </div>
          <div class="bf-panel-note common-text">
            Currently, supported asset types are limited. More asset types and various public chains will be supported
            in phases.
          </div>
        </div>
        <div class="bf-panel">
          <div class="bf-panel-head">
            <div class="bf-panel-head-icon">
              <i class="common-icon-contract"></i>
              <div class="bf-panel-head-angle">
                <i class="common-icon-eth"></i>
              </div>
            </div>
            <div class="bf-panel-head-text font-black">
              F(X) Investment Contract <br />
              <div class="bf-panel-head-subtext">(ERC20)</div>
            </div>
          </div>
          <div class="bf-panel-contract-icon">
            <i class="common-icon-interest"></i>
          </div>
          <div class="bf-panel-contract-title font-black">Earn Interest</div>
          <div class="bf-panel-contract-note">
            Via AAVE / Maker / Compound etc.
          </div>
          <div class="bf-panel-line">
            <img src="@/assets/images/derivatives/benefit/der_03_line.png" alt="" />
          </div>
          <div class="bf-coin-list bf-coin-contract">
            <div class="bf-coin-item" v-for="(item, key) in coinList" :key="key">
              <div class="bf-coin-icon">
                <i :class="[item.icon]"></i>
              </div>
              <div class="bf-coin-name font-black">{{ item.name }}</div>
            </div>
          </div>
        </div>
        <div class="bf-panel">
          <div class="bf-panel-head">
            <div class="bf-panel-head-icon">
              <i class="common-icon-fxchain"></i>
              <div class="bf-panel-head-angle"></div>
            </div>
            <div class="bf-panel-head-text font-black">Function X Chain</div>
          </div>
          <div class="bf-chain-body">
            <div class="bf-chain-line" v-for="(item, key) in 3" :key="key">
              <img src="@/assets/images/derivatives/benefit/der_03_line.png" alt="" />
            </div>
            <div class="bf-chain-list">
              <div class="bf-chain-list-item" v-for="(item, key) in chainList" :key="key">
                <div class="bf-chain-list-icon">
                  <div class="bf-chain-list-icon-item">
                    <i :class="item.icon"></i>
                  </div>
                  <div class="bf-chain-list-icon-angle">
                    <i :class="item.iconAngle"></i>
                  </div>
                </div>
                <div class="bf-chain-list-title font-black">
                  {{ item.title }}<span class="bf-chain-list-title-angle">{{ item.titleAngle }}</span>
                </div>
              </div>
              <div class="bf-chain-list-item">
                <div class="bf-chain-list-icon">
                  <div class="bf-chain-list-icon-item" v-for="(item, key) in chainAngleList" :key="key">
                    <i :class="item"></i>
                  </div>
                </div>
                <div class="bf-chain-list-title font-black">
                  <span class="bf-chain-list-title-angle">MORE</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { animeTitleShow } from '@/tools/utils'
import { gsapDestroy } from '@/tools/mixin'

export default {
  name: 'Benefit',
  data() {
    return {
      coinList: [
        {
          icon: 'common-icon-coin-list-npxs',
          name: 'PUNDIX'
        },
        {
          icon: 'common-icon-coin-list-fx',
          name: 'FX'
        },
        {
          icon: 'common-icon-coin-list-dai',
          name: 'DAI'
        },
        {
          icon: 'common-icon-coin-list-usdt',
          name: 'USDT'
        },
        {
          icon: 'common-icon-coin-list-usdc',
          name: 'USDC'
        }
      ],
      chainList: [
        {
          icon: 'common-icon-benefit-tesla',
          title: 'TSLA',
          iconAngle: 'common-icon-coin-list-dai',
          titleAngle: '/DAI'
        },
        {
          icon: 'common-icon-benefit-coinbase',
          title: 'COIN',
          iconAngle: 'common-icon-coin-list-usdt',
          titleAngle: '/USDT'
        },
        {
          icon: 'common-icon-benefit-apple',
          title: 'AAPL',
          iconAngle: 'common-icon-coin-list-usdc',
          titleAngle: '/USDC'
        },
        {
          icon: 'common-icon-benefit-amazon',
          title: 'AMZN',
          iconAngle: 'common-icon-coin-list-dai',
          titleAngle: '/DAI'
        },
        {
          icon: 'common-icon-benefit-nvidia',
          title: 'NVDA',
          iconAngle: 'common-icon-coin-list-usdt',
          titleAngle: '/USDT'
        }
      ],
      chainAngleList: [
        'common-icon-benefit-microsoft',
        'common-icon-benefit-facebook',
        'common-icon-benefit-paypal',
        'common-icon-benefit-ferrari'
      ]
    }
  },
  mixins: [gsapDestroy],
  mounted() {
    document.fonts.ready.then(() => {
      this.commonGsapArray[0] = animeTitleShow({
        titleEl: this.$refs.title,
        textEl: this.$refs.text,
        scrollTrigger: {
          trigger: this.$refs.textBox,
          start: 'bottom bottom',
          end: 'bottom bottom'
        }
      })
    })
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/style/mixin.scss';

$panelContentWidth: 235px;
$panelWidth: 305px;
$actionLineWidth: 80px;
$actionWidth: 144px;
$actionLineDelay: 0.4;
$actionLineDur: 1;
.benefit {
  width: 100%;
  padding-top: 126px;
  .bf-context {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 1000px;
  }
  .bf-note {
    margin-top: 8px;
    width: 890px;
  }
  .bf-content {
    margin-top: 70px;
    width: 1200px;
    position: relative;
  }
  .bf-action-box {
    @include position-all();
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .bf-action {
    width: $actionWidth;
    height: 140px;
    background-size: 100%;
    background-position: center;
    font-size: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 22px;
    position: relative;
    overflow: hidden;
    & + .bf-action {
      margin-left: $panelWidth;
    }
    &:nth-of-type(1) {
      background-image: url('~@/assets/images/derivatives/benefit/der_03_deposit.png');
      .bf-action-line {
        background-image: linear-gradient(270deg, #ffc6fd 0%, rgba(149, 154, 255, 0) 100%);
        right: 100%;
        &:nth-of-type(1) {
          top: 25px;
          animation: #{$actionLineDelay + $actionLineDur + 's'} line-run 0s linear infinite;
        }
        &:nth-of-type(2) {
          bottom: 29px;
          animation: #{$actionLineDelay + $actionLineDur + 's'} line-run #{$actionLineDelay + 's'} linear infinite;
        }
      }
    }
    &:nth-of-type(2) {
      background-image: url('~@/assets/images/derivatives/benefit/der_03_cross.png');
      .bf-action-line {
        background-image: linear-gradient(270deg, #a6e7ff 0%, rgba(149, 154, 255, 0) 100%);
        right: 100%;
        &:nth-of-type(1) {
          top: 28px;
          animation: #{$actionLineDelay + $actionLineDur + 's'} line-run #{$actionLineDelay + 's'} linear infinite;
        }
        &:nth-of-type(2) {
          bottom: 26px;
          animation: #{$actionLineDelay + $actionLineDur + 's'} line-run 0s linear infinite;
        }
      }
    }
  }
  .bf-action-line {
    position: absolute;
    width: $actionLineWidth;
    height: 3px;
    border-radius: 0 2px 2px 0;
  }
  .bf-panel-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    z-index: 5;
  }
  .bf-panel {
    width: $panelWidth;
    height: 595px;
    background-image: url('~@/assets/images/derivatives/benefit/der_03_board.png');
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
  }
  .bf-panel-head {
    width: 100%;
    height: 128px;
    padding: 30px 34px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .bf-panel-head-icon {
    position: relative;
    width: 88px;
    flex-shrink: 0;
  }
  .bf-panel-head-angle {
    position: absolute;
    bottom: 10px;
    right: -13px;
    width: 34px;
  }
  .bf-panel-head-text {
    font-size: 26px;
    color: #f7f7f7;
    text-align: center;
    line-height: 26px;
    font-weight: 500;
    margin-left: 14px;
  }
  .bf-panel-head-subtext {
    font-size: 18px;
    line-height: 1em;
  }
  .bf-panel-line {
    width: $panelContentWidth;
    margin-left: auto;
    margin-right: auto;
  }

  .bf-coin-list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    &.bf-coin-erc20 {
      $top: 34px;
      $bottom: 38px;
      min-height: 232px + $top + $bottom;
      padding-top: $top;
      padding-bottom: $bottom;
      .bf-coin-item {
        min-width: 105px;
      }
    }
    &.bf-coin-contract {
      $top: 13px;
      $bottom: 16px;
      min-height: 216px + $top + $bottom;
      padding-top: $top;
      padding-bottom: $bottom;
      .bf-coin-item {
        min-width: 110px;
      }
    }
  }
  .bf-coin-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .bf-coin-icon {
    flex-shrink: 0;
    width: 32px;
    border-radius: 200px;
    overflow: hidden;
  }
  .bf-coin-name {
    margin-left: 14px;
    font-size: 26px;
    margin-bottom: -4px;
  }

  .bf-panel-note {
    width: $panelContentWidth;
    margin-left: auto;
    margin-right: auto;
    margin-top: 14px;
    font-size: 14px;
  }

  .bf-panel-contract-icon {
    width: 100px;
    margin: 18px auto 10px;
  }
  .bf-panel-contract-title {
    text-align: center;
    font-size: 26px;
  }
  .bf-panel-contract-note {
    text-align: center;
    font-size: 14px;
    margin-bottom: 10px;
  }

  .bf-chain-body {
    position: relative;
  }
  .bf-chain-line {
    position: absolute;
    height: 4px;
    img {
      width: 100%;
      height: 100%;
    }
    &:nth-of-type(1),
    &:nth-of-type(2) {
      width: 200px;
      left: 50%;
      transform: translateX(-50%);
    }
    &:nth-of-type(1) {
      top: 142px;
    }
    &:nth-of-type(2) {
      top: 280px;
    }
    &:nth-of-type(3) {
      width: 320px;
      left: 50%;
      top: 58px;
      transform-origin: left center;
      transform: rotate(90deg);
    }
  }
  .bf-chain-list {
    width: 100%;
    padding-left: 42px;
    padding-right: 42px;
    padding-top: 36px;
    display: grid;
    grid-template-rows: auto auto auto;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 60px;
  }
  .bf-chain-list-item {
    width: 103px;
    &:nth-last-of-type(1) {
      .bf-chain-list-icon {
        display: grid;
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 2px;
        grid-column-gap: 2px;
        width: 50px;
        height: 50px;
      }
      .bf-chain-list-title {
        margin-top: 8px;
      }
    }
  }
  .bf-chain-list-icon {
    width: 40px;
    position: relative;
    margin-left: 28px;
  }
  .bf-chain-list-icon-item {
    width: 100%;
    border-radius: 40px;
    overflow: hidden;
  }
  .bf-chain-list-icon-angle {
    position: absolute;
    top: 24px;
    left: 24px;
    width: 24px;
    overflow: hidden;
    border-radius: 24px;
  }
  .bf-chain-list-title {
    text-align: center;
    margin-top: 16px;
    font-size: 26px;
    line-height: 1em;
  }
  .bf-chain-list-title-angle {
    font-size: 18px;
    line-height: 26px;
  }
}
@keyframes line-run {
  #{ $actionLineDur / ($actionLineDelay + $actionLineDur) * 100 + '%'} {
    transform: translateX($actionLineWidth + $actionWidth);
  }
  100% {
    transform: translateX($actionLineWidth + $actionWidth);
  }
}
</style>
