<template>
  <Default>
    <div class="derivatives">
      <div class="panel-item">
        <Visualization />
      </div>
      <div class="panel-item">
        <Contract />
      </div>
      <div class="panel-item whole">
        <Trading />
      </div>
      <div class="panel-item">
        <Benefit />
      </div>
      <div class="panel-item whole">
        <Participate />
      </div>
    </div>
  </Default>
</template>

<script>
/* eslint-disable */
import Default from '@/components/layout/Default'
import Market from '@/components/derivatives/Market'
import Visualization from '@/components/derivatives/Visualization'
import Contract from '@/components/derivatives/Contract'
import Trading from '@/components/derivatives/Trading'
import Participate from '@/components/derivatives/Participate'
import Benefit from '@/components/derivatives/Benefit'
export default {
  name: 'Derivatives',
  components: {
    Benefit,
    Participate,
    Trading,
    Contract,
    Visualization,
    Market,
    Default
  }
}
</script>

<style scoped></style>
