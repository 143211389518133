var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contract"},[_c('div',{ref:"textBox1",staticClass:"ct-main-text"},[_c('div',{staticClass:"common-transition-box"},[_c('h2',{ref:"title1",staticClass:"ct-title"},[_vm._v(" Providing a 24x7 tokenized stocks perpetual contract trading platform ")])]),_c('div',{ref:"text1",staticClass:"common-text ct-note"},[_vm._v(" Users can bridge ERC20 stablecoins into Function X network's f(x)Bridge cross-chain module. Thereafter, users can trade stock-based perpetual contract on Function X DEX chain around the clock using these bridged stablecoins. The process is decentralized and transparent. Initially Function X DEX chain will offer popular assets for trading and gradually open to new assets listing via community voting and governance. ")])]),_c('div',{staticClass:"ct-sub-content"},[_c('div',{staticClass:"ct-img"},[_c('div',{ref:"machine",staticClass:"ct-machine"},[_c('img',{attrs:{"src":require("@/assets/images/derivatives/contract/der_02_machine.gif"),"alt":""}}),_vm._m(0),_c('div',{staticClass:"ct-ray"},[_c('svg',{attrs:{"width":"190px","height":"43px","viewBox":"0 0 190 43"}},[_c('g',{attrs:{"stroke":"#454545","stroke-width":"2","fill-rule":"evenodd","fill":"none"}},[_c('path',{attrs:{"d":"M 1 31.226619 L 42.878 26.998 L 54.879 2 L 78.452 40 L 91.798 9.188 L 98.975 21.130 L 189,12.234","stroke-dasharray":"120 260","stroke-dashoffset":_vm.rayOffset}})])])])])]),_c('div',{ref:"textBox2",staticClass:"ct-context"},[_c('div',{staticClass:"ct-change"},[_c('div',{staticClass:"ct-coin-box"},[_c('div',{staticClass:"ct-coin",style:({
              opacity: _vm.coinOpacity,
              transform: `translateY(${_vm.coinY}px)`
            })},[_c('img',{attrs:{"src":require("@/assets/images/derivatives/contract/der_02_coin.png"),"alt":""}})])]),_c('div',{staticClass:"ct-stone-box"},[_c('div',{staticClass:"ct-stone",style:({ opacity: _vm.stoneROpacity })},[_c('img',{attrs:{"src":require("@/assets/images/derivatives/contract/der_02_stone.png"),"alt":""}})]),_c('div',{staticClass:"ct-stone",style:({
              opacity: _vm.stoneLOpacity,
              transform: `translateX(${_vm.stoneLX}px)`
            })},[_c('img',{attrs:{"src":require("@/assets/images/derivatives/contract/der_02_stone.png"),"alt":""}})])])]),_c('div',{staticClass:"common-transition-box"},[_c('h2',{ref:"title2",staticClass:"ct-sub-title"},[_vm._v(" The advantage of stock-based perpetual contract ")])]),_c('div',{ref:"text2",staticClass:"common-text ct-sub-note"},[_vm._v(" Decentralized trading exchanges not only trades cryptocurrency assets, but also stocks, commodities and others, making this process more decentralized without human intervention and linked to the price of the asset itself. We will adopt a perpetual contract method that uses the price of the asset at three different time - the time when transaction starts, during transaction, and when the transaction is closed to feed the price, and automatically adjust the handling fee rate of the long and short sides, so that the trading price is at market price. ")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ct-robot"},[_c('img',{attrs:{"src":require("@/assets/images/derivatives/contract/der_02_xrobot_a.png"),"alt":""}})])
}]

export { render, staticRenderFns }