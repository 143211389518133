<template>
  <div class="participate">
    <div class="pp-bg"></div>
    <div class="pp-robot">
      <img
        src="@/assets/images/derivatives/participate/der_04_xrobot.gif"
        alt=""
      />
    </div>
    <div class="pp-context">
      <h2 class="pp-title">How to participate</h2>
      <div class="common-text pp-note">
        Please follow Function X's official webpage, social media channels for
        the latest update and how long term holder of $FX will benefit along the
        way.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Participate'
}
</script>

<style scoped lang="scss">
.participate {
  padding-top: 388px;
  padding-bottom: 131px;
  width: 100%;
  position: relative;
  .pp-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 519px;
    background-image: url('~@/assets/images/derivatives/participate/der_04_bg.gif');
    background-size: auto 100%;
    background-position: center top;
    background-repeat: no-repeat;
  }
  .pp-robot {
    position: absolute;
    top: 44px;
    width: 656px;
    left: 50%;
    transform: translateX(-50%);
  }
  .pp-context {
    position: relative;
    z-index: 5;
    width: 534px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}
</style>
