<template>
  <div class="visualization">
    <div class="common-transition-box" ref="titleBox">
      <h2 class="vz-title" ref="title">
        The traditional world's money and markets visualization
      </h2>
    </div>
    <div class="vz-board" ref="board">
      <div class="vz-board-bg">
        <img src="@/assets/images/derivatives/visualization/der_01_chart.gif" alt="" />
      </div>
      <div class="vz-array-box">
        <div class="vz-array" :class="{ anime: anime }">
          <img src="@/assets/images/derivatives/visualization/der_01_data.svg" alt="" />
          <div class="vz-array-mask">
            <img src="@/assets/images/derivatives/visualization/02.svg" alt="" />
          </div>
          <div class="vz-array-mask">
            <img src="@/assets/images/derivatives/visualization/03.svg" alt="" />
          </div>
          <div class="vz-array-mask">
            <img src="@/assets/images/derivatives/visualization/04.svg" alt="" />
          </div>
        </div>
      </div>
      <div class="vz-coin-box">
        <div class="vz-coin-item" v-for="(item, key) of coin" :key="key" v-show="key === arrayShow">
          <img :src="require('@/assets/images/derivatives/visualization/' + item)" alt="" />
        </div>
      </div>
      <div class="vz-robot">
        <img src="@/assets/images/derivatives/visualization/der_01_xrobot.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { ScrollTrigger } from '@/tools/gsap'
import { animeTitleShow } from '@/tools/utils'
import { gsapDestroy } from '@/tools/mixin'

export default {
  name: 'Visualization',
  data() {
    return {
      show: false,
      coin: ['der_01_question-mark.png', 'der_01_stone.png', 'der_01_coin.png', 'der_01_fx.png'],
      anime: false,
      arrayShow: 0,
      arrayTarget: 0,
      arrayNum: 3
    }
  },
  methods: {
    getChangeArray(dur, target, num) {
      const d = 0.04
      let length = Math.floor(dur / d)
      length--
      if (dur % d !== 0) {
        length--
      }

      const res = []
      res.push({
        id: target,
        dur: dur - d * length
      })
      for (let i = 1; i <= length; i++) {
        const obj = {}
        obj.id = (target + (num - 1) * i) % num
        obj.dur = d
        res.push(obj)
      }
      return res
    },
    changeTarget(arr) {
      if (arr.length === 0) {
        return
      }
      const obj = arr.pop()
      setTimeout(() => {
        this.arrayShow = obj.id
        this.changeTarget(arr)
      }, obj.dur * 1000)
    }
  },
  mixins: [gsapDestroy],
  mounted() {
    document.fonts.ready.then(() => {
      // 字体加载完成后的逻辑
      this.show = true
      this.$store.commit('setBridgeResourceReady', true)
    })
    const slotAnime1 = this.getChangeArray(1, 1, this.coin.length)
    const slotAnime2 = this.getChangeArray(0.8, 2, this.coin.length)
    const slotAnime3 = this.getChangeArray(1, 3, this.coin.length)
    const slotWait1 = { id: 1, dur: 0.6 }
    const slotWait2 = { id: 2, dur: 0.6 }
    const slotArray = [...slotAnime3, slotWait2, ...slotAnime2, slotWait1, ...slotAnime1]

    this.commonGsapArray[0] = ScrollTrigger.create({
      trigger: this.$refs.board,
      start: 'bottom bottom',
      end: 'bottom bottom',
      // toggleActions: 'restart none reverse none',
      markers: process.env.NODE_ENV === 'development',
      onEnter: () => {
        this.anime = true
        this.changeTarget(slotArray)
      }
    })

    // 字体加载完成前后高度变化非常大 必须在字体加载完增加与标题相关的动画
    document.fonts.ready.then(() => {
      this.commonGsapArray[1] = animeTitleShow({
        titleEl: this.$refs.title,
        scrollTrigger: {
          trigger: this.$refs.titleBox,
          start: 'bottom bottom',
          end: 'bottom bottom'
        }
      })
    })
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/style/mixin.scss';

$coinMargin: 37px;
$maskDurShow: 4;
$maskDurHide: 0.5;
$maskDelay: -2;

.visualization {
  width: 100%;
  padding-top: 47px;
  padding-bottom: 114px;
  .vz-title {
    width: 890px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  .llllll {
    overflow: hidden;
  }
  .vz-board {
    width: 1057px;
    height: 543px;
    margin-top: 85px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
  }
  .vz-board-bg {
    @include position-all();
  }
  .vz-array-box {
    position: absolute;
    top: 140px;
    left: 96px;
    width: 688px;
    height: 337px;
    overflow: hidden;
  }
  .vz-array {
    transform-origin: left top;
    transform: scale(2.875);
    &.anime {
      animation-delay: 0s, 1.6s, 3s, 4s;
      animation-duration: 1s, 0.8s, 1s, 0.8s;
      animation-name: array-scale-1, array-scale-2, array-scale-3, array-opacity;
      animation-fill-mode: forwards;
      .vz-array-mask {
        &:nth-of-type(1) {
          animation: #{($maskDurShow + $maskDurHide + $maskDelay) * 3 + 's'} mask-flash 4.8s linear infinite;
        }
        &:nth-of-type(2) {
          animation: #{($maskDurShow + $maskDurHide + $maskDelay) * 3 + 's'} mask-flash #{4.8 +
            ($maskDurShow + $maskDurHide + $maskDelay) + 's'} linear infinite;
        }
        &:nth-of-type(3) {
          animation: #{($maskDurShow + $maskDurHide + $maskDelay) * 3 + 's'} mask-flash #{4.8 +
            (($maskDurShow + $maskDurHide + $maskDelay) * 2) + 's'} linear infinite;
        }
      }
    }
  }
  .vz-array-mask {
    @include position-all();
    opacity: 0;
  }
  .vz-coin-box {
    position: absolute;
    bottom: 79px;
    right: 84px;
    width: 144px;
    height: 136px;
    overflow: hidden;
    background-image: url('~@/assets/images/derivatives/visualization/der_01_aim.svg');
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .vz-coin-item {
    position: absolute;
    width: 70px;
    height: 70px;
  }
  .vz-robot {
    position: absolute;
    width: 176px;
    height: 218px;
    left: -44px;
    bottom: -63px;
  }
}

@keyframes array-scale-1 {
  100% {
    // 46 / 18
    transform: scale(2.555);
  }
}
@keyframes array-scale-2 {
  0% {
    transform: scale(2.555);
  }
  100% {
    // 46 / 23
    transform: scale(2);
  }
}
@keyframes array-scale-3 {
  0% {
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes array-opacity {
  0%,
  33.3%,
  66.6%,
  100% {
    opacity: 1;
  }
  16.6%,
  50%,
  83.3% {
    opacity: 0.9;
  }
}
@keyframes mask-flash {
  0% {
    opacity: 0;
  }
  #{$maskDurShow / (($maskDurShow + $maskDurHide + $maskDelay) * 3) * 100 + '%' } {
    opacity: 1;
  }
  #{($maskDurShow + $maskDurHide) / (($maskDurShow + $maskDurHide + $maskDelay) * 3) * 100 + '%' } {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
</style>
